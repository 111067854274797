import * as React from "react";
import {
  CallToAction,
  FAQ,
  Layout,
  MainBackground,
  ProductBenifits,
  ProductDescription,
  ProductHeader,
} from "../../components/containers";
import SEO from "../../components/marketing/Seo";

import Accordion from "../../components/utility/accordian/Accordion";

import { Card } from "../../components/utility/Card";
import { Energy_Meta } from "../../data/MetaData";

import {
  DescriptionContent,
  FAQS,
  HeaderData,
  Keybenifits,
} from "../../data/product-page-data/BusinessEnergyData";

const BusinessEnergy = () => {
  return (
    <Layout>
      <SEO title={Energy_Meta.title} description={Energy_Meta.description} />
      <ProductHeader
        src={HeaderData.src}
        subTitle={HeaderData.subtitle}
        title={HeaderData.title}
        paragraph={HeaderData.paragraph}
        to={HeaderData.to}
      />

      <MainBackground>
        <ProductDescription
          title={DescriptionContent.title}
          paragraph={DescriptionContent.paragraph}
          to={DescriptionContent.to}
          profitPoint1={DescriptionContent.benifits1}
          profitPoint2={DescriptionContent.benifits2}
          profitPoint3={DescriptionContent.benifits3}
          profitPoint4={DescriptionContent.benifits4}
        />
        <CallToAction />
        <ProductBenifits
          benifit={Keybenifits.map((item, id) => {
            return (
              <Card
                key={id}
                icon={<item.src />}
                title={item.title}
                paragraph={item.paragraph}
              />
            );
          })}
        />{" "}
        <FAQ>
          {FAQS.map((item) => {
            return <Accordion title={item.title} content={item.answer} />;
          })}
        </FAQ>
      </MainBackground>
    </Layout>
  );
};

export default BusinessEnergy;
