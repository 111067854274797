import {
  HiddenCost,
  NoObligation,
  PersonalisedService,
  ProtectPrivacy,
  TeamExperts,
  TimeStres,
} from "../../svgs/WhyDigital_Svgs";
import Internet from "../../images/ProductHeaderImages/BusinessEnergy.jpeg";
import { Product_Quote_Routes } from "../Routes";

export const Keybenifits = [
  {
    id: 1,
    src: HiddenCost,
    title: "Competitive Pricing",
    paragraph: "Cost-effective plans for small businesses.",
  },
  {
    id: 2,
    src: TimeStres,
    title: "Premium Energy Providers",
    paragraph: "Get quotes from the best energy providers on the marke",
  },
  {
    id: 3,
    src: NoObligation,
    title: "Continued Energy Usage",
    paragraph:
      "With cost-effective plans, you can use the same amount of energy for less.",
  },
  {
    id: 4,
    src: ProtectPrivacy,
    title: "Honest Service",
    paragraph: "No hidden fees or secret charges.",
  },
  {
    id: 5,
    src: PersonalisedService,
    title: "Discounts and Incentives",
    paragraph: "Reductions available to loyal customers.",
  },
  {
    id: 6,
    src: TeamExperts,
    title: "Sustainability Options",
    paragraph: "Be rewarded for choosing sustainable sources.",
  },
];

export const HeaderData = {
  src: Internet,
  subtitle: "Get the Best Deal for Your Business Energy Needs",
  title: "Business Energy",
  paragraph: "Compare the top energy suppliers now",
  to: Product_Quote_Routes.BusinessEnergy,
};

export const DescriptionContent = {
  title: "Why Do You Need Business Energy Comparisons?",
  paragraph:
    "It’s important to compare energy plans because the majority of Australian businesses are paying more than necessary for electricity. By comparing energy suppliers, you’ll be able to choose the right plan that will eliminate unnecessary hidden costs for your business.",
  to: Product_Quote_Routes.BusinessEnergy,
  benifits1: "No needless or hidden fees",
  benifits2: "Access to the best energy rates available",
  benifits3: "Plans that suit all businesses",
  benifits4: "Reduced stress with honest, reliable plans",
};

export const FAQS = [
  {
    title: "What’s wrong with my current energy rates?",
    answer:
      "Most Australian businesses are paying too much for electricity. Find out if you could be paying less by comparing energy rates through our free service.   ",
  },
  {
    title: "What is renewable energy?",
    answer:
      "Renewable energy comes from resources that are naturally replenished. Some examples are solar energy from sunlight and wind energy.  ",
  },
  {
    title: "What are standing energy contracts?",
    answer:
      "Standing energy contracts are gas and electricity contracts with terms and conditions that are set by Australian law.",
  },
  {
    title: "What are market retail contracts?",
    answer:
      "Market retail contracts have slightly different terms and conditions from standing electricity contracts. For example, a market retail contract might have different billing periods.",
  },
  {
    title: "Can Digital Comparison find rates for all kinds of businesses?",
    answer:
      "Definitely. Our leading industry partners provide energy plans and rates to all businesses, no matter the size or field.",
  },
  {
    title:
      "When I switch energy providers, will my gas or electricity supply be affected?",
    answer:
      "No. The company that provides your gas and electricity is the distributor and this will not change. Only the billing details will be affected.",
  },
  {
    title: "How can Digital Comparison get me better energy deals?",
    answer:
      "We use our network of energy providers to get you obligation-free quotes that you can compare side by side. ",
  },
  {
    title: "Which energy provider is the best?",
    answer:
      "We vouch for all our energy supply partners. The right one for you depends on your unique circumstances and requirements.   ",
  },
];
